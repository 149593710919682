import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import DataTableClientInfo from "../../../../components/TableElement/DataTableClientInfo/DataTableClientInfo";
import { convertDateToLongString } from "../../../../tools/convertDateToString";
import { PENDING, SUCCESS } from "../../../../store/globalStatuses/globalStatuses";
import Modal from "react-modal";
import { sendBonusForUser } from "../../../../api";
import styles from './ClientInfo.module.scss';
import ButtonOutlined from "../../../../components/FormElements/ButtonOutlined";
import closeModalImage from "../../../../components/PayModal/assets/CloseModalIcon.svg";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import phoneImg from "../../../Analytics/components/RFMClients/assets/images/phone.png";
import branchApi from "../../../../store/branchStore/branchApi";
import { store } from "react-notifications-component";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../../helpers";
import {Roles} from "../../../../config";
import {useCurrentBranch} from "../../../../hooks/useCurrentBranch";

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        padding: 0,
        borderRadius: 24,
    }
};

export default function ClientInfo(props) {
    const { t } = useTranslation();
    const {
        customer,
        cStatus,
        page,
        transactions,
        tStatus,
        changeTransactionPageAction,
        count,
        getBackToClients,
        setBonusesModal,
        bonusesModal,
        info,
        status,
    } = props
    const {id} = useParams()
    const [amountSum, setAmountSum] = useState('');
    const [isSent, setIsSent] = useState(false);
    const [pending, setPending] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [pushMessage, setPushMessage] = useState('');
    const [bonusModal, setBonusModal] = useState(false);
    const [bonusModalStep, setBonusModalStep] = useState(1);
    const [bonus, setBonus] = useState(0);
    const [modalPending, setModalPending] = useState(false);
    const { customer: customerInfo } = useSelector(s => s.clientInfoReducer);
    const { currentRole } = useCurrentBranch()
    const params = Object.fromEntries(new URLSearchParams(location.search));
    const changeAmountSum = (e) => {
        if (isNaN(Number(e.target.value))) return
        setAmountSum(e.target.value);
    }

    const onChangePrice = (e) => {
        if (isFinite(+e.currentTarget.value)) {
            if (!info || Number(info.balance).toFixed() < +e.currentTarget.value) return
            setBonus(+e.currentTarget.value);
        }
    }

    const sendBonus = () => {
        setPending(true);
        sendBonusForUser(info.id, customer.id, {amount: Number(amountSum), comment: ''})
          .then((res) => {
              setIsSent(true);
          })
          .finally(() => {
              setPending(false);
          });
    }

    const onCloseModal = () => {
        if (isSent) setIsSent(false);
        setBonusesModal(false);
        setAmountSum('');
    }

    const openBonusesModal = () => {
        // setBonusesModal(true);
        setBonusModal(true);
    }

    const onChangePushMessage = (e) => {
        if (e.target.value.length < 150) {
            setPushMessage(e.target.value);
        }
    }

    const onSendPushToCustomers = () => {
        if (!customerInfo || !customerInfo.id || !pushMessage) return

        setModalPending(true);
        branchApi.sendPushToCustomers(info.id, {user_ids: [customerInfo.id], message: pushMessage})
          .then((res) => {
              store.addNotification({
                  title: t('success'),
                  message: t('successMessageSent'),
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                      duration: 5000,
                      onScreen: true
                  }
              });
              setPushMessage('');
          })
          .finally(() => {
              setModalPending(false);
              setSendModal(false);
          });
    }

    const onSendBonusesToUsers = () => {
        if (!info || !bonus || !customer.id) return

        setModalPending(true);
        branchApi.sendBonusesToUsers(info.id, {user_ids: [customer.id], group_id: info.group_id, amount: bonus})
          .then((res) => {
              store.addNotification({
                  title: t('success'),
                  message: t('NSClients.successBonus'),
                  type: "success",
                  insert: "top",
                  container: "top-right",
                  animationIn: ["animate__animated", "animate__fadeIn"],
                  animationOut: ["animate__animated", "animate__fadeOut"],
                  dismiss: {
                      duration: 5000,
                      onScreen: true
                  }
              });
          })
          .finally(() => {
              setModalPending(false);
              setBonus(0);
              setBonusModalStep(1);
              setBonusModal(false);
          });
    }

    const renderBonusExpiration = () => {
        if (customer.bonuses_expire !== 0 && !customer.bonuses_expire) {
            return null
        }

        return <p>{t('NSBonus.expirationBonus')}:</p>
    }
    const renderBonusExpirationData = () => {
        if (customer.bonuses_expire !== 0 && !customer.bonuses_expire) {
            return null
        }

        return (
          <span>
            {customer.bonuses_expire === 0 ? '∞' : formatDate(customer.bonuses_expire)}
          </span>
        )
    }

    return (
      <div className={styles.container}>
          <Modal open={bonusesModal}
                 onClickClose={onCloseModal}
            >
                {isSent ? (
                  <>
                      <div className={styles.header}>
                          <p>{t('NSBonusModal.success')}</p>
                      </div>
                      <div className={styles.sendButton}>
                          <button
                            onClick={onCloseModal}
                            disabled={pending}
                          >
                              {t('close')}
                          </button>
                      </div>
                  </>
                ) : (
                  <>
                      <div className={styles.header}>
                          <p>{t('NSPayment.assessment')}</p>
                          {
                              (!!customer.first_name || !!customer.last_name) && (
                                <span>{`${customer.first_name} ${customer.last_name}`}</span>
                              )
                          }
                      </div>
                      <div className={styles.points}>
                          <p>{t('scoreCount')}:</p>
                          <span>{customer.bonuses > 0 ? Math.round(customer.bonuses) : 0}</span>
                      </div>

                      <div className={styles.getPoints}>
                          <p>{t('NSClients.bonusCount')}</p>
                          <input
                            value={amountSum}
                            onChange={changeAmountSum}
                          />
                      </div>

                      <div className={styles.sendButton}>
                          <button
                            onClick={sendBonus}
                            disabled={pending}
                          >
                              {t('NSTransactions.bonus')}
                          </button>
                      </div>
                  </>
                )}
            </Modal>
            <i className="fa fa-chevron-left" aria-hidden="true"
               style={{position: "absolute", left: 34, top: 54, cursor: 'pointer'}}
               onClick={getBackToClients}/>
            <div className={styles.dataWrapper}>
                <div className={styles.itemTitle}>
                    <p>{t('NSRespondents.name')}:</p>
                    <p style={{margin: '46px 0'}}>{t('NSAuth.phone')}:</p>
                    <p style={{marginBottom: 46}}>{t('status')}:</p>
                    <p style={{marginBottom: 46}}>{t('bonuses')}:</p>
                    <p style={{marginBottom: 46}}>{t('NSSidebar.awards')}:</p>
                    <p style={{marginBottom: 46}}>{t('NSClients.сameFrom')}:</p>
                    {renderBonusExpiration()}
                </div>
                <div className={styles.itemValue} style={{margin: '0 55px 0 22px'}}>
                    <span>{cStatus === SUCCESS && customer.first_name.length && customer.last_name.length ? `${customer.first_name} ${customer.last_name}` : (
                        cStatus === SUCCESS && customer.first_name.length ? customer.first_name : (cStatus === SUCCESS && customer.last_name.length ? customer.last_name : '-')
                    )}</span>
                    <span
                        style={{margin: '8px 0'}}>{cStatus === SUCCESS && customer.phone.length ? customer.phone : '-'}</span>
                    {cStatus === SUCCESS && customer.client_type === "RETURNED"
                        ?
                        <span style={{color: "#4EB78B", marginBottom: 8}}>{t('loyal1')}</span>
                        :
                        (cStatus === SUCCESS && customer.client_type === "NEW" ?
                            <span style={{color: "#6979F8", marginBottom: 8}}>{t('new1')}</span> :
                            <span style={{marginBottom: 8}}>-</span>)
                    }
                    <span style={{marginBottom: 8}}> {cStatus === SUCCESS && customer.bonuses > 0 ? Math.floor(customer.bonuses) : '0'}</span>
                    <span style={{marginBottom: 8}}>{(customer.received_stamp_count || '0') + '/' + (customer.stamp_count || '0')}</span>
                    <span style={{marginBottom: 8}}>{customer.come_from || t('NSComparison.notPoint')}</span>
                    {renderBonusExpirationData()}
                </div>
                <div className={styles.itemTitle}>
                    <p>{t('sumPayments')}:</p>
                    <p style={{margin: '46px 0'}}>{t('NSGeneral.average')}:</p>
                    <p style={{marginBottom: 46}}>{t('dateOfFirstBuy')}:</p>
                    <p style={{marginBottom: 46}}>{t('dateOfLastBuy')}:</p>
                    <p style={{marginBottom: 46}}>{t('NSClients.visitsCount')}:</p>
                    {customer.birthday ? (
                      <>
                          <p style={{marginBottom: 46}}>{t('takenGifts')}:</p>
                          <p>{t('NSBonus.birthday')}:</p>
                      </>
                    ) : (
                      <p>{t('takenGifts')}:</p>
                    )}
                </div>
                <div className={styles.itemValue} style={{marginLeft: 11}}>
                    <span>{cStatus === SUCCESS && customer.total_amount > 0 ? `${customer.total_amount}` : '0'}</span>
                    <span
                        style={{margin: '8px 0'}}>{cStatus === SUCCESS && customer.average_check > 0 ? `${Math.round(customer.average_check)}` : '0'}</span>
                    <span
                        style={{marginBottom: 8}}>{cStatus === SUCCESS && customer.first_order ? convertDateToLongString(customer.first_order) : '-'}</span>
                    <span style={{marginBottom: 8}}> {cStatus === SUCCESS && customer.last_order > 0 ? convertDateToLongString(customer.last_order) : '-'}</span>
                    <span style={{marginBottom: 8}}>{customer.transactions_count || '0'}</span>

                    {customer.birthday ? (
                      <>
                          <span style={{marginBottom: 8}}>{customer.total_awards || '0'}</span>
                          <span>{customer.birthday || null}</span>
                      </>
                    ) : (
                      <span>{customer.total_awards || '0'}</span>
                    )}
                </div>
            </div>
            {currentRole === Roles.ANALYTIC && params.groupId ? null : (
                <div style={{display: 'flex', alignItems: 'center', marginTop: 30}}>
                    <button
                        onClick={openBonusesModal}
                        disabled={!customer.id}
                        className={styles.button}
                        style={{opacity: `${!customer.id ? .3 : 1}`, marginRight: 30, marginTop: 0}}
                    >
                        {t('NSTransactions.bonus')}
                    </button>
                    <ButtonFilled variant="purple"
                                  onClick={() => setSendModal(true)}>{t('NSStatistics.notification')}</ButtonFilled>
                    <div style={{width: 16}}/>
                    <ButtonFilled variant="green" to={`/clients/client/edit/${id}`}>{t('edit')}</ButtonFilled>
                </div>
            )}

          <h3>{t('NSGeneral.trans')}</h3>
          <DataTableClientInfo
              transactions={transactions}
              tStatus={tStatus}
              page={page}
              changeTransactionPageAction={changeTransactionPageAction}
              count={count}
          />
          <Modal
              ariaHideApp={false}
              isOpen={bonusModal}
              style={modalStyles}
              onRequestClose={() => setBonusModal(false)}
          >
              <div className={styles.modal}>
                  <div className={styles.closeModal} onClick={() => setBonusModal(false)}>
                      <img src={closeModalImage}/>
                  </div>
                  <div className={styles.modalContainer}>
                        {
                            bonusModalStep == 1 ? (
                              <>
                                  <div className={styles.header}>
                                      <p>{t('NSPayment.assessment')}</p>
                                  </div>
                                  <div className={styles.bonuses}>
                                      {/*<div className={styles.bonusesHeader}>*/}
                                      {/*    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                                      {/*        <circle cx="9" cy="9" r="9" fill="#E7E7E6"/>*/}
                                      {/*        <rect x="8" y="6" width="2" height="8" rx="1" fill="#A7A9AF"/>*/}
                                      {/*        <circle cx="9" cy="4" r="1" fill="#A7A9AF"/>*/}
                                      {/*    </svg>*/}
                                      {/*    <p>*/}
                                      {/*        {t('NSClients.bonus')}*/}
                                      {/*    </p>*/}
                                      {/*</div>*/}
                                      <p className={styles.subTitle} style={{ marginTop: 80 }}>
                                          {t('NSClients.bonusCount')}
                                      </p>
                                      <input
                                        style={{ marginBottom: 24 }}
                                        value={bonus}
                                        className={styles.inputBonus}
                                        onChange={onChangePrice} />

                                      {/*<p className={styles.subTitle}>*/}
                                      {/*    {t('NSClients.clients')}*/}
                                      {/*</p>*/}
                                      {/*<div className={styles.bonusInput}>1</div>*/}
                                      {/*<div style={{ height: '20px' }} />*/}
                                      {/*<p className={styles.subTitle}>*/}
                                      {/*    {t('NSClients.sum')}*/}
                                      {/*</p>*/}
                                      {/*<div className={`${styles.bonusInput} ${styles.green}`}>{bonus * 1}</div>*/}
                                      {/*<div className={styles.cash}><span>{t('NSClients.sumWithdrawn')}</span> &nbsp; <b>*/}
                                      {/*    {*/}
                                      {/*        info && info.balance ?*/}
                                      {/*          Number(info.balance) % 1 !== 0 ?*/}
                                      {/*            Number(info.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") :*/}
                                      {/*            info.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0*/}
                                      {/*    }*/}
                                      {/*</b></div>*/}

                                      <ButtonFilled
                                        className={styles.btn}
                                        disabled={!bonus}
                                        onClick={() => {
                                            setBonusModalStep(2);
                                        }}
                                      >{t('proceed')}</ButtonFilled>
                                  </div>
                              </>
                            ) : (
                              <>
                                  <div className={styles.header}>
                                      <div style={{ cursor: 'pointer', marginRight: "auto", marginBottom: 40 }} onClick={() => setBonusModalStep(1)}>
                                          <svg style={{ marginRight: 'auto' }} width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <rect width="2.70393" height="15.6376" rx="1.35196" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 13.0293 2.64099)" fill="#AFAFAB"/>
                                              <rect x="12.9746" y="21.1027" width="2.62964" height="15.6376" rx="1.31482" transform="rotate(135 12.9746 21.1027)" fill="#AFAFAB"/>
                                          </svg>
                                      </div>
                                  </div>
                                  <div className={styles.bonusesResult}>
                                      <div><span>{t('NSClients.giveBonus')}</span> <span>{bonus}</span></div>
                                      {/*<div><span>{t('NSClients.availableBalance')}</span>*/}
                                      {/*    <span>*/}
                                      {/*        {info && info.balance ?*/}
                                      {/*          Number(info.balance) % 1 !== 0 ?*/}
                                      {/*            Number(info.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") :*/}
                                      {/*            info.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0}*/}
                                      {/*      </span>*/}
                                      {/*</div>*/}
                                      {/*<div><span>{t('NSClients.clients')}</span> <span>{1}</span></div>*/}

                                      <div className={styles.bonusesResultItem}><b>{t('NSClients.toSum')}</b> <span>{bonus * 1} ₸</span></div>

                                      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', margin: '50px auto 0', height: '70%' }}>
                                          <ButtonFilled
                                            className={styles.btn}
                                            loading={modalPending}
                                            onClick={onSendBonusesToUsers}
                                          >{t('NSClients.submitPayment')}</ButtonFilled>
                                      </div>
                                  </div>
                              </>
                            )
                        }
                    </div>
                </div>
            </Modal>
            <Modal
              ariaHideApp={false}
              isOpen={sendModal}
              style={modalStyles}
              onRequestClose={() => setSendModal(false)}
            >
                <div className={styles.modal}>
                    <div className={styles.closeModal} onClick={() => setSendModal(false)}>
                        <img src={closeModalImage}/>
                    </div>
                    <div className={styles.modalContainer}>
                        <div className={styles.header}>
                            <p>{t('NSClients.not')}</p>
                        </div>

                        <div>
                            <p className={styles.p}>{t('NSClients.textNot')}</p>
                            <textarea
                              placeholder={t('Form.enterText')}
                              className={styles.textArea}
                              value={pushMessage}
                              onChange={onChangePushMessage}
                              required
                            />
                            <div className={styles.span} style={{ color: `${pushMessage.length >= 149 ? 'tomato' : '#000000'}` }}>{t('maxSym', {sym: 150})}</div>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '16px' }}>
                                <img width="230" src={phoneImg} style={{ marginBottom: '16px' }}/>
                                <ButtonFilled
                                  className={styles.btn}
                                  onClick={onSendPushToCustomers}
                                  loading={modalPending}
                                >{t('send')}</ButtonFilled>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
