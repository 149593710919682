import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import _ from 'lodash';
import ButtonFilled from "../../components/FormElements/ButtonFilled";
import ButtonOutlined from "../../components/FormElements/ButtonOutlined";
import TextField from "../../components/FormElements/TextField";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker";
import clientsActions from "../../store/clientsStore/clientsActions";
import {convertDateToLongString, convertDateToShortString} from "../../tools/convertDateToString";
import { PENDING } from "../../store/globalStatuses/globalStatuses";
import CustomPagination from "../../components/CustomPagination";
import Checkbox from "../../components/FormElements/Checkbox";
import Loader from "../../components/Loader";
import branchApi from "../../store/branchStore/branchApi";
import { store } from "react-notifications-component";
import Modal from "react-modal";
import closeModalImage from "../../components/PayModal/assets/CloseModalIcon.svg";
import phoneImg from "../Analytics/components/RFMClients/assets/images/phone.png";
import findImg from '../../assets/icons/find.svg';
import { Roles } from "../../config";
import { useCurrentBranch } from "../../hooks/useCurrentBranch";
import { exportBranchCustomers } from "../../api";
import * as queryString from "querystring";
import styles from './Clients.module.scss';
import Invis from "../../components/Filter/Invis";
import { format } from "date-fns";
import { getDate } from "../../helpers";

const cancelSvg = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="17.1758" y="5.32983" width="1.67514" height="16.7514" rx="0.837569" transform="rotate(45 17.1758 5.32983)" fill="#EB5757"/>
        <rect x="18.3613" y="17.175" width="1.67514" height="16.7514" rx="0.837569" transform="rotate(135 18.3613 17.175)" fill="#EB5757"/>
    </svg>
);

const arrows = (
    <svg width="17" height="14" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.4 13C4.4 13.3314 4.66863 13.6 5 13.6C5.33137 13.6 5.6 13.3314 5.6 13L4.4 13ZM5.42426 0.575736C5.18995 0.341421 4.81005 0.341421 4.57574 0.575736L0.757359 4.39411C0.523045 4.62843 0.523045 5.00833 0.757359 5.24264C0.991674 5.47696 1.37157 5.47696 1.60589 5.24264L5 1.84853L8.39411 5.24264C8.62843 5.47696 9.00833 5.47696 9.24264 5.24264C9.47696 5.00833 9.47696 4.62843 9.24264 4.39411L5.42426 0.575736ZM5.6 13L5.6 1L4.4 1L4.4 13L5.6 13Z" fill="black"/>
        <path d="M11.4 1C11.4 0.668629 11.6686 0.4 12 0.4C12.3314 0.4 12.6 0.668629 12.6 1L11.4 1ZM12.4243 13.4243C12.19 13.6586 11.8101 13.6586 11.5757 13.4243L7.75736 9.60589C7.52304 9.37157 7.52304 8.99167 7.75736 8.75736C7.99167 8.52304 8.37157 8.52304 8.60589 8.75736L12 12.1515L15.3941 8.75736C15.6284 8.52304 16.0083 8.52304 16.2426 8.75736C16.477 8.99167 16.477 9.37157 16.2426 9.60589L12.4243 13.4243ZM12.6 1L12.6 13L11.4 13L11.4 1L12.6 1Z" fill="black"/>
    </svg>
);

const modalStyles = {
    overlay: {
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none',
        padding: 0,
        borderRadius: 24,
    }
};

const Clients = () => {
    const { t } = useTranslation();
    const { currentRole, currentBranch } = useCurrentBranch();
    const history = useHistory();
    const dispatch = useDispatch();
    const { info, pending } = useSelector(s => s.branchReducer);
    const profile = useSelector(state => state.profileReducer)
    const {
        clients,
        chosenClients,
        count,
        params,
        status,
    } = useSelector(s => s.clientsReducer);
    const location = useLocation();
    const queryParams = queryString.parse(location.search.slice(1));
    const invisParams = useSelector(state => state.filtersReducer)
    const [pushMessage, setPushMessage] = useState('');
    const [bonus, setBonus] = useState(0);
    const [modalPending, setModalPending] = useState(false);
    const [activeChoice, setActiveChoice] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [bonusModal, setBonusModal] = useState(false);
    const [bonusModalStep, setBonusModalStep] = useState(1);
    const searchRef = useRef(null);

    const debounced = _.debounce(() => {
        dispatch(clientsActions.getClients(info.id, {
            ...params,
            branch_ids: params.branch_ids.length ? params.branch_ids.map((b) => b.value) : null,
            city_ids: params.city_ids.length ? params.city_ids.map((c) => c.value) : null,
            roles: [currentRole],
            groupId: info.group_id,
        }, searchRef));
    }, 1500);

    useEffect(() => {
        if (info && info.id) {
            if (params.query) {
                debounced();
            } else {
                dispatch(clientsActions.getClients(info.id, {
                    ...params,
                    branch_ids: params.branch_ids.length ? params.branch_ids.map((b) => b.value) : null,
                    city_ids: params.city_ids.length ? params.city_ids.map((c) => c.value) : null,
                    roles: [currentRole],
                    groupId: info.group_id,
                }))
            }

            let paramsForQuery = {}
            for (let key in params) {
                if (params[key] || params[key]?.length || key === 'skip' || !Array.isArray(params[key])) {
                    paramsForQuery[key] = params[key]
                }
                if (params.branch_ids.length) {
                    paramsForQuery['branch_ids'] = params.branch_ids.map((b) => b.value || b);
                }
                if (params.city_ids.length) {
                    paramsForQuery['city_ids'] = params.city_ids.map((c) => c.value || c);
                }
            }
            history.push({
                search: `?${queryString.stringify(paramsForQuery)}`
            })
        }
        return () => debounced.cancel();
    }, [params, info]);

    useEffect(() => {
        const allParams = { ...params, ...queryParams }
        let paramsForQuery = {}
        for (let key in allParams) {
            if (allParams[key] || allParams[key]?.length || key === 'skip' || !Array.isArray(allParams[key])) {
                paramsForQuery[key] = allParams[key]
            }
            if (allParams.branch_ids.length) {
                paramsForQuery['branch_ids'] = allParams.branch_ids.map((b) => b.value || b);
            }
            if (allParams.city_ids.length) {}
            paramsForQuery['city_ids'] = allParams.city_ids.map((c) => c.value || c);
        }

        history.push({
            pathname: '/clients',
            search: `?${queryString.stringify(paramsForQuery)}`
        });
    }, []);

    const onSendBonusesToUsers = () => {
        if (!info || !bonus || !chosenClients.length) return

        setModalPending(true);
        branchApi.sendBonusesToUsers(info.id, {user_ids: chosenClients, group_id: info.group_id, amount: bonus})
            .then((res) => {
                store.addNotification({
                    title: t('success'),
                    message: t('NSClients.successBonus'),
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
            })
            .finally(() => {
                setModalPending(false);
                setBonus(0);
                setBonusModalStep(1);
                setBonusModal(false);
            });
    }

    const onSkip = (quantityOfSkip, cb) => {
        dispatch(clientsActions.changeParams({
            skip: quantityOfSkip,
        }))
        cb();
    }

    const onChooseClient  = (customer) => (e) => {
        dispatch(clientsActions.changeChosenClients(customer));
    }

    const onChangePrice = (e) => {
        if (isFinite(+e.currentTarget.value)) {
            if (!info || Number(info.balance).toFixed() < +e.currentTarget.value) return
            setBonus(+e.currentTarget.value);
        }
    }

    const onChangePushMessage = (e) => {
        if (e.target.value.length < 150) {
            setPushMessage(e.target.value);
        }
    }

    const onDisableActiveChoice = () => {
        setActiveChoice(prev => !prev);
        dispatch(clientsActions.clearChosenClients());
    }

    const onSendPushToCustomers = () => {
        if (!info || !pushMessage || !chosenClients.length) return

        setModalPending(true);
        branchApi.sendPushToCustomers(info.id, {user_ids: chosenClients, message: pushMessage})
            .then((res) => {
                store.addNotification({
                    title: t('success'),
                    message: t('successMessageSent'),
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                });
                setPushMessage('');
            })
            .finally(() => {
                setModalPending(false);
                setSendModal(false);
            });
    }

    const changeFilter = (value) => () => {
        dispatch(clientsActions.changeParams({
            ['customer_filter_type']: value,
            page: 1,
            skip: 0,
        }))
    }

    const renderPhone = (phone = '') => {
        if (phone.length) {
            if (phone.includes('+')) return phone
            return '+' + phone
        }
        return '-'
    }

    const renderTable = () => {
        return (
            <table className="table">
                <thead>
                <tr>
                    <th></th>
                    <th>#</th>
                    <th>{t('client')}</th>
                    <th>{t('NSAuth.phone')}</th>
                    <th>{t('status')}</th>
                    <th onClick={changeFilter(params['customer_filter_type'] > 0 ? -1 : 1)}>{t('NSGeneral.trans')} {arrows}</th>
                    <th onClick={changeFilter(params['customer_filter_type'] > 0 ? -4 : 4)}>{t('bonuses')} {arrows}</th>
                    <th onClick={changeFilter(params['customer_filter_type'] > 0 ? -2 : 2)}>{t('NSPurchase.total')} {arrows}</th>
                    <th> {t('NSClients.gradation_status')}</th>
                    <th onClick={changeFilter(params['customer_filter_type'] > 0 ? -3 : 3)}>{t('NSPurchase.last')} {arrows}</th>
                    <th>{t('NSClients.sourceBonuses')}</th>
                    <th>Рассылка</th>
                </tr>
                </thead>
                <tbody>
                {clients.map((customer, index) => (
                    <tr key={customer.id}>
                        <td data-type="checkbox">
                            {activeChoice && (
                                <Checkbox checkedType="square" checked={customer.checked} onChange={onChooseClient(customer)} />
                            )}
                        </td>
                        <td onClick={(e) => history.push(`/clients/client/${customer.id}`)}>{status !== PENDING && params.page === 1 ? index + 1 : (params.page - 1) * 10 + index + 1}</td>
                        <td onClick={(e) => history.push(`/clients/client/${customer.id}`)}>
                            {customer.first_name.length && customer.last_name.length ? `${customer.first_name} ${customer.last_name}` : (
                            customer.first_name.length ? customer.first_name : (customer.last_name.length ? customer.last_name : '-')
                        )}</td>
                        <td onClick={(e) => history.push(`/clients/client/${customer.id}`)}>
                            {renderPhone(customer?.phone || '')}
                        </td>
                        <td onClick={(e) => history.push(`/clients/client/${customer.id}`)}>
                            {customer.client_type === "RETURNED"
                                ?
                                <span style={{color: "#4EB78B"}}>{t('loyal')}</span>
                                :
                                (customer.client_type === "NEW" ?
                                    <span style={{color: "#6979F8"}}>{t('new')}</span> :
                                    <span>-</span>)

                            }
                        </td>
                        <td onClick={(e) => history.push(`/clients/client/${customer.id}`)}>{customer.transactions_count}</td>
                        <td onClick={(e) => history.push(`/clients/client/${customer.id}`)}>{(customer.cashback_amount && Math.round(customer.cashback_amount)) || 0}</td>
                        <td onClick={(e) => history.push(`/clients/client/${customer.id}`)}>{customer.total_amount}</td>
                        <td onClick={(e) => history.push(`/clients/client/${customer.id}`)}>{customer.gradation_status || ''}</td>
                        <td onClick={(e) => history.push(`/clients/client/${customer.id}`)}>{convertDateToShortString(customer.last_order)}</td>
                        <td onClick={(e) => history.push(`/clients/client/${customer.id}`)}>
                            {customer.come_from || t('NSComparison.notPoint')}
                        </td>
                        <td>
                            {customer.last_uploaded_notification ? convertDateToShortString(customer.last_uploaded_notification) : '-'}
                        </td>
                    </tr>
                ))}
                {!clients.length && (
                  <tr>
                      <td>-</td>
                      <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr>
                )}
                </tbody>
            </table>
        )
    }

    return (
      <div className={styles.container}>
          <div className={styles.header}>
              <div className={styles.headerTop}>
                  <div>
                      <TextField
                        ref={searchRef}
                        icon={findImg}
                        placeholder={t('NSClients.search', {phone: 7077087778})}
                        value={params.query}
                        disabled={status === PENDING}
                        onChange={(e) => {
                            dispatch(clientsActions.changeParams({
                                query: e.target.value,
                                page: 1,
                                skip: 0,
                            }))
                        }}
                      />
                  </div>
                  <div>
                      {!!chosenClients.length ? (
                        <>
                            <ButtonOutlined
                              color="black"
                              variant="green"
                              disabled={status === PENDING}
                              onClick={() => setBonusModal(true)}
                            >{t('NSTransactions.bonus')}</ButtonOutlined>
                            <ButtonFilled
                              onClick={() => setSendModal(true)}>{t('NSStatistics.notification')}</ButtonFilled>
                        </>
                      ) : (
                        <ButtonFilled
                          // disabled={info?.group_id !== 1 || status === PENDING}
                          disabled={status === PENDING}
                          to="clients/notifications"
                        >
                            {t('NSStatistics.notification')}
                        </ButtonFilled>
                      )}
                  </div>
              </div>
              <div className={styles.headerBottom}>
                  <div>
                      <h6>{t('NSStatistics.last')}</h6>
                      <CustomDatePicker
                        trans={true}
                        tStartDate={getDate(params.from)}
                        tEndDate={getDate(params.to)}
                        tSetStartDate={(v) => {
                            dispatch(clientsActions.changeParams({
                                from: v ? format(v, 'DD-MM-YYYY') : null,
                                page: 1,
                                skip: 0,
                            }))
                        }}
                        tSetEndDate={(v) => {
                            dispatch(clientsActions.changeParams({
                                to: v ? format(v, 'DD-MM-YYYY') : null,
                                page: 1,
                                skip: 0,
                            }))
                        }}
                      />
                  </div>
                  {activeChoice ? (
                    <div style={{marginBottom: '-22px'}}>
                        <ButtonOutlined
                          prefix={cancelSvg}
                          color="red"
                          disabled={status === PENDING}
                          onClick={onDisableActiveChoice}
                        >
                            <span style={{
                                fontSize: '18px',
                                transform: 'translate(-9px, -2px)',
                                display: 'block'
                            }}>{t('cancel')}</span>
                        </ButtonOutlined>
                    </div>
                  ) : (
                    <Checkbox label={t('NSPurchase.select')} color="#73D389"
                              onChange={() => setActiveChoice(prev => !prev)}
                    />
                  )}
              </div>

              {currentRole === Roles.ANALYTIC ? (
                <div style={{marginLeft: 'auto', width: '100%', padding: '24px 10px 0 0px'}}>
                    <Invis
                      loading={status === PENDING}
                      changeCb={() => {
                          dispatch(clientsActions.changeParams({
                              ...params,
                              ...invisParams,
                              page: 1,
                              skip: 0,
                          }))
                      }}
                    />
                </div>
              ) : null}
          </div>
          {!!count && <p style={{textAlign: 'right'}}>Общее количество: {count}</p>}
          <div style={{position: 'relative'}}>
              {(status === PENDING || pending) && (
                <div className={styles.loader}>
                    <Loader color="#000" style={{zIndex: 100, opacity: 1}}/>
                </div>
              )}
              {renderTable()}
          </div>
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <div>
                  <CustomPagination
                    count={count}
                    limit={params.limit}
                    max={4}
                    loading={status === PENDING}
                    updated={false}
                    page={params.page}
                    setPage={(v) => {
                        dispatch(clientsActions.changeParams({
                            page: v,
                        }))
                    }}
                    onSkip={onSkip}
                  />
              </div>
              {currentRole && currentRole !== Roles.CASHIER && currentBranch && currentBranch.id && (
                <ButtonFilled loading={downloading} onClick={() => {
                    setDownloading(true);
                    const [startD, endD] = getStartAndDate;
                    exportBranchCustomers(currentBranch.id, startD, endD)
                      .then((res) => {
                          const a = document.createElement('a');
                          document.body.appendChild(a);
                          a.download = currentBranch.name + ` - ${t('clients')}`;
                          a.href = res.data;
                          a.click();
                      })
                      .finally(() => setDownloading(false))
                }}>
                    {t('export')}
                </ButtonFilled>
              )}
          </div>

          <Modal
            ariaHideApp={false}
            isOpen={bonusModal}
            style={modalStyles}
            onRequestClose={() => setBonusModal(false)}
          >
              <div className={styles.modal}>
                  <div className={styles.closeModal} onClick={() => setBonusModal(false)}>
                      <img src={closeModalImage}/>
                  </div>
                  <div className={styles.modalContainer}>
                      {
                          bonusModalStep == 1 ? (
                            <>
                                <div className={styles.header}>
                                    <p>{t('NSPayment.assessment')}</p>
                                </div>
                                <div className={styles.bonuses}>
                                    <div className={styles.bonusesHeader}>
                                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="9" cy="9" r="9" fill="#E7E7E6"/>
                                            <rect x="8" y="6" width="2" height="8" rx="1" fill="#A7A9AF"/>
                                            <circle cx="9" cy="4" r="1" fill="#A7A9AF"/>
                                        </svg>
                                        <p>
                                            {t('NSClients.bonus')}
                                        </p>
                                    </div>
                                    <p className={styles.subTitle}>
                                        {t('NSClients.bonusCount')}
                                    </p>
                                    <input
                                      value={bonus}
                                      className={styles.inputBonus}
                                      onChange={onChangePrice}/>

                                    <p className={styles.subTitle}>
                                        {t('NSClients.clients')}
                                    </p>
                                    <div className={styles.bonusInput}>{chosenClients.length}</div>
                                    <div style={{height: '20px'}}/>
                                    <p className={styles.subTitle}>
                                        {t('NSClients.sum')}
                                    </p>
                                    <div
                                      className={`${styles.bonusInput} ${styles.green}`}>{bonus * chosenClients.length}</div>
                                    {/*<div className={styles.cash}><span>{t('NSClients.sumWithdrawn')}</span> &nbsp; <b>*/}
                                    {/*    {*/}
                                    {/*        info && info.balance ?*/}
                                    {/*            Number(info.balance) % 1 !== 0 ?*/}
                                    {/*                Number(info.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") :*/}
                                    {/*                info.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0*/}
                                    {/*    }*/}
                                    {/*</b></div>*/}

                                    <ButtonFilled
                                      className={styles.btn}
                                      disabled={!bonus}
                                      onClick={() => {
                                          setBonusModalStep(2);
                                      }}
                                    >{t('proceed')}</ButtonFilled>
                                </div>
                            </>
                          ) : (
                            <>
                                <div className={styles.header}>
                                    <div style={{cursor: 'pointer'}} onClick={() => setBonusModalStep(1)}>
                                        <svg style={{marginRight: 'auto'}} width="23" height="23" viewBox="0 0 23 23"
                                             fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="2.70393" height="15.6376" rx="1.35196"
                                                  transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 13.0293 2.64099)"
                                                  fill="#AFAFAB"/>
                                            <rect x="12.9746" y="21.1027" width="2.62964" height="15.6376" rx="1.31482"
                                                  transform="rotate(135 12.9746 21.1027)" fill="#AFAFAB"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className={styles.bonusesResult}>
                                    <div><span>{t('NSClients.giveBonus')}</span> <span>{bonus}</span></div>
                                    <div>
                                        {/*<span>{t('NSClients.availableBalance')}</span>*/}
                                        <span>
                                              {info && info.balance ?
                                                Number(info.balance) % 1 !== 0 ?
                                                  Number(info.balance).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") :
                                                  info.balance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") : 0}
                                            </span>
                                    </div>
                                    <div><span>{t('NSClients.clients')}</span> <span>{chosenClients.length}</span></div>

                                    <div className={styles.bonusesResultItem}><b>{t('NSClients.toSum')}</b>
                                        <span>{bonus * chosenClients.length} ₸</span></div>

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'flex-end',
                                        margin: 'auto auto 0',
                                        height: '70%'
                                    }}>
                                        <ButtonFilled
                                          className={styles.btn}
                                          loading={modalPending}
                                          onClick={onSendBonusesToUsers}
                                        >{t('NSClients.submitPayment')}</ButtonFilled>
                                    </div>
                                </div>
                            </>
                          )
                      }
                  </div>
              </div>
          </Modal>

          <Modal
            ariaHideApp={false}
            isOpen={sendModal}
            style={modalStyles}
            onRequestClose={() => setSendModal(false)}
          >
              <div className={styles.modal}>
                  <div className={styles.closeModal} onClick={() => setSendModal(false)}>
                      <img src={closeModalImage}/>
                  </div>
                  <div className={styles.modalContainer}>
                      <div className={styles.header}>
                          <p>{t('NSClients.not')}</p>
                      </div>

                      <div>
                          <p className={styles.p}>{t('NSClients.textNot')}</p>
                          <textarea
                            placeholder={t('Form.enterText')}
                            className={styles.textArea}
                            value={pushMessage}
                            onChange={onChangePushMessage}
                            required
                          />
                          <div className={styles.span}
                               style={{color: `${pushMessage.length >= 149 ? 'tomato' : '#000000'}`}}>{t('maxSym', {sym: 150})}</div>

                          <div
                            style={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '16px'}}>
                              <img width="230" src={phoneImg} style={{marginBottom: '16px'}}/>
                              <ButtonFilled
                                className={styles.btn}
                                onClick={onSendPushToCustomers}
                                loading={modalPending}
                              >{t('send')}</ButtonFilled>
                          </div>
                      </div>
                  </div>
              </div>
          </Modal>
      </div>
    )
}

export default Clients;
