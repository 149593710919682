import React, {useState, useEffect} from 'react'
import styles from './styles.module.scss'
import closeModalImage from "../../../../components/PayModal/assets/CloseModalIcon.svg";
import Modal from "react-modal";
import branchApi from "../../../../store/branchStore/branchApi";
import clientsActions from "../../../../store/clientsStore/clientsActions";
import {Roles} from "../../../../config";
import Checkbox from "../../../../components/FormElements/Checkbox";
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {useCurrentBranch} from "../../../../hooks/useCurrentBranch";
import { store } from "react-notifications-component";

const SendNotificationModal = (props) => {
  const { info, pending } = useSelector(s => s.branchReducer);
  const [modalInvisParams, setModalInvisParams] = useState({
    city_ids: [],
    branch_ids: [],
  });
  const { t } = useTranslation();
  const [modalPending, setModalPending] = useState(false);
  const [pushMessage, setPushMessage] = useState('')
  const [disableAutonotificationStatus, setDisableAutonotificationStatus] = useState(false);
  const [historyOfNotifications, setHistoryOfNotifications] = useState([])
  const { currentRole, currentBranch, groupId } = useCurrentBranch();
  const {
    clients,
    chosenClients,
    count,
    params,
    status,
  } = useSelector(s => s.clientsReducer);
  const [branchIds, setBranchIds] = useState([]);

  const onChangePushMessage = (e) => {
    if (e.target.value.length <= 625) {
      setPushMessage(e.target.value);
    }
  }

  const onDisableAutoNotification = () => {
    if (!info) return

    setDisableAutonotificationStatus(true);
    branchApi.branchesCustomersUploadedStop(info.group_id)
        .then((res) => {
          store.addNotification({
            title: t('success'),
            message: 'Рассылка успешно остановлена',
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        })
        .finally(() => {
          setDisableAutonotificationStatus(false);
        });
  }

  const onSendPushToCustomers = () => {
    if (!info || !pushMessage) return

    if (!chosenClients.length) {} else {}

    setModalPending(true);
    // branchApi.branchesCustomersUploadedSend(info.id, {user_ids: chosenClients, message: pushMessage})
    if (currentRole === Roles.ANALYTIC && groupId) {
      const queryParams = `?branch_ids=${branchIds.join(',')}`
      branchApi.branchesAnalyticCustomersUploadedSend(groupId, {
        branch_ids: branchIds,
      },{ message: pushMessage })
          .then((res) => {
            store.addNotification({
              title: t('success'),
              message: t('successMessageSent'),
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            });
            setPushMessage('');
          })
          .finally(() => {
            setModalPending(false);
            props.onRequestClose();
          });
    } else {
      branchApi.branchesCustomersUploadedSend(info.id, { message: pushMessage })
          .then((res) => {
            store.addNotification({
              title: t('success'),
              message: t('successMessageSent'),
              type: "success",
              insert: "top",
              container: "top-right",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
                onScreen: true
              }
            });
            setPushMessage('');
          })
          .finally(() => {
            setModalPending(false);
            props.onRequestClose();
          });
    }

  }
  const calculateHistoryCount = () => {
    if (historyOfNotifications && historyOfNotifications.length) {
      let countsOfBranches = 0

      historyOfNotifications.forEach((item) => {
        if (item.history && item.history.length) {
          item.history.forEach((historyItem, index) => {
            if (historyItem.sent_count && index === (item.history.length - 1)) {
              countsOfBranches += historyItem.sent_count
            }
          })
        }
      })

      return countsOfBranches
    }

    return 0
  }

  const calculateHistoryItemCount = (history = []) => {
    if (history && history.length) {
      let countsOfBranches = 0

      history.forEach((historyItem, index) => {
        if (historyItem.sent_count && index === (history.length - 1)) {
          countsOfBranches += historyItem.sent_count
        }
      })

      return countsOfBranches
    }

    return 0
  }

  const renderDate = (item) => {
    if (item.history && item.history.length) {
      if (item.history[item.history.length - 1].last_date) {
        return new Intl.DateTimeFormat('ru-RU',
            { year: 'numeric', month: 'numeric', day: 'numeric' }
        ).format(history.last_date)
      }
    }


    return ''
  }

  useEffect(() => {
    let intervalId = null
    if (info?.group_id) {
      branchApi.branchesCustomersUploadedHistory(info.group_id)
          .then((response) => {
            setHistoryOfNotifications(response?.data || [])
          })

      intervalId = setInterval(() => {
        branchApi.branchesCustomersUploadedHistory(info.group_id)
            .then((response) => {
              setHistoryOfNotifications(response?.data || [])
            })
      }, 10000)
    }

    return () => {
      intervalId && clearInterval(intervalId)
    }
  }, [info?.group_id]);

  return (
    <Modal
      {...props}
    >
      <div className={styles.modal}>
        <div className={styles.closeModal} onClick={props.onRequestClose}>
          <img src={closeModalImage}/>
        </div>
        <div className={styles.leftContent}>
          <div className={styles.modalContainer}>
            <div className={styles.header}>
              <p>{t('NSClients.not')}</p>
            </div>
            <div>
              <p className={styles.p}>{t('NSClients.textNot')}</p>
              <textarea
                placeholder={t('Form.enterText')}
                className={styles.textArea}
                value={pushMessage}
                onChange={onChangePushMessage}
                required
              />
              <div className={styles.span}
                   style={{color: `${pushMessage.length >= 624 ? 'tomato' : '#000000'}`}}>
                {t('maxSym', {sym: 625})}
              </div>

              <div style={{display: 'flex', gap: 8, flexDirection: 'column', marginTop: '16px'}}>
                {/*<Invis*/}
                {/*  invisParams={modalInvisParams}*/}
                {/*  setInvisParams={setModalInvisParams}*/}
                {/*  isVisibleProp*/}
                {/*/>*/}

                <ButtonFilled
                  className={styles.stopBtn}
                  onClick={onDisableAutoNotification}
                  loading={modalPending || disableAutonotificationStatus}
                >
                  Прервать предыдущую авторассылку
                </ButtonFilled>
                {/*<img width="230" src={phoneImg} style={{ marginBottom: '16px' }}/>*/}
                <ButtonFilled
                  className={styles.btn}
                  onClick={onSendPushToCustomers}
                  disabled={!branchIds.length || !pushMessage}
                  loading={modalPending || disableAutonotificationStatus}
                >
                  {t('send')}
                </ButtonFilled>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.rightContent}>
          <p className={styles.p}>История рассылок</p>

          <p>Общее кол-во отправок - {calculateHistoryCount()}</p>

          {historyOfNotifications.length ? (
            <ul className={styles.history}>
              <li>
                <p>
                  <Checkbox
                    checked={branchIds.length}
                    onChange={() => {
                      if (branchIds.length) {
                        setBranchIds([])
                      } else {
                        setBranchIds(historyOfNotifications.map(({branch_id}) => branch_id))
                      }
                    }}
                  />
                </p>
                <p>Филиал</p>
                <p>Дата</p>
                <p>Отправлено</p>
              </li>
              {historyOfNotifications.map((history, index) => (
                <li key={history.branch_id} className={index % 2 ? styles.active : styles.passive}>
                  <p>
                    <Checkbox
                        checked={branchIds.includes(history.branch_id)}
                        onChange={() => {
                          if (branchIds.includes(history.branch_id)) {
                            setBranchIds(branchIds.filter((id) => id !== history.branch_id))
                          } else {
                            setBranchIds([...branchIds, history.branch_id])
                          }
                        }}
                    />
                  </p>
                  <p><span>{history.name}</span></p>
                  <p>
                    {renderDate(history)}
                  </p>
                  <p>
                  <span>
                      {/*{history && history?.history && history?.history[0].sent_count || 0}*/}
                    {calculateHistoryItemCount(history?.history || [])}
                  </span>
                  </p>
                </li>
              ))}
            </ul>
          ) : null}
        </div>
      </div>
    </Modal>
  )
}

export default SendNotificationModal