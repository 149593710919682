import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import styles from './ClientInfo.module.scss';
import ButtonFilled from "../../../../components/FormElements/ButtonFilled";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import TextField from "../../../../components/FormElements/TextField";
import { CustomSingleDatePicker } from "../../../../components/CustomDatePicker/CustomDatePicker";
import clientsApi from "../../../../store/clientsStore/clientsApi";
import { convertDate, convertDateYToString } from "../../../../tools/convertDateToString";
import { PENDING } from "../../../../store/globalStatuses/globalStatuses";
import { PhoneInputField } from "../../../../components/PhoneInput";

export default function ClientInfo(props) {
    const { t } = useTranslation();
    const {
        customer,
        getBackToClients,
        info,
    } = props
    const { id } = useParams()
    const history = useHistory();
    const { customer: customerInfo, cStatus } = useSelector(s => s.clientInfoReducer);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [birthday, setBirthday] = useState(null);
    const [pending, setPending] = useState(null);
    const [deleting, setDeleting] = useState(null);
    const query = useLocation();
    const searchParams = new URLSearchParams(query.search);

    function isValidDate(d) {
        return d instanceof Date && !isNaN(d);
    }

    useEffect(() => {
        if (customerInfo) {
            setFirstName(customerInfo.first_name || "");
            setLastName(customerInfo.last_name || "");
            setPhoneNumber(customerInfo.phone || "");
            let d = null;
            if (customerInfo.birthday) {
                const splitted = customerInfo.birthday.split("-");
                d = `${splitted[2]}-${splitted[1]}-${splitted[0]}`;
            }
            if (isValidDate(new Date(d))) {
                setBirthday(d);
            } else {
                setBirthday(null);
            }
        }
    }, [customerInfo]);

    const save = () => {
        setPending(true);
        if (customerInfo) {
            let newB = null;
            if (birthday) {
                newB = convertDate(birthday).split('.').join('-')
            }
            clientsApi.putClient(customerInfo.id, {
                first_name: firstName,
                last_name: lastName,
                birthday: newB,
                new_phone_number: phoneNumber,
            })
              .then(() => {
                  if (searchParams.get("from") === "transaction" && searchParams.get("transactionId")) {
                      return history.push(`/?transactionId=${searchParams.get("transactionId")}`);
                  } else {
                      history.push("/clients");
                  }
              })
              .finally(() => {
                  setPending(false);
              })
        }
    }

    const handleDelete = () => {
        setDeleting(true);
        if (customerInfo) {
            clientsApi.deleteClient(info.id, customerInfo.id)
                .then(() => {
                    if (searchParams.get("from") === "transaction" && searchParams.get("transactionId")) {
                        return history.push(`/?transactionId=${searchParams.get("transactionId")}`);
                    } else {
                        history.push("/clients");
                    }
                })
                .finally(() => {
                    setDeleting(false);
                })
        }
    }


    const onClickBack = () => {
        if (searchParams.get("from") === "transaction" && searchParams.get("transactionId")) {
            return history.push(`/?transactionId=${searchParams.get("transactionId")}`);
        }
        history.goBack();
    }

    return (
        <div className={styles.container}>
            <i className="fa fa-chevron-left" aria-hidden="true"
               style={{position: "absolute", left: 34, top: 54, cursor: 'pointer'}}
               onClick={onClickBack}/>
            <form className={styles.form}>
                <div className={styles.formInner}>
                    <TextField
                      label={t('Form.firstName')}
                      placeholder={t('Form.enterFirstName')}
                      disabled={pending || cStatus === PENDING}
                      // required

                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <TextField
                      label={t('Form.lastName')}
                      placeholder={t('Form.enterLastName')}
                      disabled={pending || cStatus === PENDING}
                      // required

                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    <div style={{ width: '234px', marginTop: '19px' }}>
                        <PhoneInputField
                          value={phoneNumber}
                          pending={pending || cStatus === PENDING}
                          onChange={(v) => setPhoneNumber(v)}
                          label={t('NSAuth.phone')}
                          placeholder={t('newPhone')}
                        />
                    </div>

                    <div style={{ width: '234px', marginTop: '19px' }}>
                        <p>{t("NSBonus.birthday")}</p>
                        <CustomSingleDatePicker
                          tSetDate={(value) => setBirthday(value)}
                          tDate={birthday}
                          maxDate={new Date(Date.now())}
                        />
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', marginTop: 30, gap: 16}}>
                    <ButtonFilled
                      variant="green"
                      onClick={save}
                      loading={pending || cStatus === PENDING}
                    >
                        {t('save')}
                    </ButtonFilled>
                    <ButtonFilled
                        variant="red"
                        onClick={handleDelete}
                        loading={pending || cStatus === PENDING || deleting}
                    >
                        {t('delete')}
                    </ButtonFilled>
                </div>
            </form>
        </div>
    )
}
